$(document).ready(function() {
  // scroll
  $(window).on("scroll", function() {
    if ($(this).scrollTop() > 50) {
      if (!$(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").addClass("navbar--scrolled");
        $(".brand__towns, .navbar-menus__top").slideUp(200);
      }
    } else {
      if ($(".navbar").hasClass("navbar--scrolled")) {
        $(".navbar").removeClass("navbar--scrolled");
        $(".brand__towns, .navbar-menus__top").slideDown(200);
      }
    }
  });

  // menu
  $(".hamburger").on("click", function() {
    $(this).toggleClass("hamburger--clicked");
    $(".menu").slideToggle(350);
    if ($(this).hasClass("hamburger--clicked")) {
      $("body").css("overflow", "hidden");
      $(".navbar").addClass("navbar--opened");
    } else {
      $("body").css("overflow", "auto");
      $(".navbar").removeClass("navbar--opened");
    }
  });
});
