$(document).ready(function() {
  $("#searchOpen, #searchOpen2").on("click", function() {
    $(".search").fadeIn(350);
    $("body").css("overflow", "hidden");
    $(".search__input").focus();
    $(".search__input").select();
    $(".menu").hide();
    $(".hamburger").removeClass("hamburger--clicked");
    $(".navbar").removeClass("navbar--scrolled navbar--opened");
  });
  $("#searchClose").on("click", function() {
    $(".search").fadeOut(350);
    $("body").css("overflow", "auto");
  });
});
