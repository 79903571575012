$(document).ready(function () {
  $("form").on("submit", function () {
    const reportValidity = $(this)[0].reportValidity();
    if (reportValidity) {
      $(this).find("button").prop("disabled", true);
    }
  });

  $("#id_request_outdoor_rewards_card").addClass(
    "checkboxinput form-check-input"
  );
});
