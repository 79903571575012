$(document).ready(function() {
  // change href # to none
  $('a[href="#"]').click(function(event) {
    event.preventDefault();
  });

  $('[data-toggle="tooltip"]').tooltip();

  $(".slick-dots li:only-child")
    .closest(".slider__controls")
    .hide();

  $("select")
    .parent("div")
    .addClass("select-parent");
});
