import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";

import L from "leaflet";
import "leaflet-defaulticon-compatibility";

$(document).ready(function () {
  if ($(".template-mappage").length === 0) return;

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    window.dataLayer.push(arguments);
  }

  $.get(window.location.pathname + "api/layers/", function (data, status) {
    if (status !== "success") console.error("API did not return layers.");

    // Set global extent for map to first layer
    const globalExtent = data.layers[0].extent;

    // Create OSM layer
    let tileLayer = L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        maxZoom: 18,
        id: "mapbox/streets-v11",
        accessToken: data.MAPBOX_ACCESS_TOKEN,
      }
    );

    // Add all layers from API to an object
    let mapLayers = {};
    for (let i = 0; i < data.layers.length; i++) {
      let layer = data.layers[i];
      let layerGroup = [];
      layerGroup.push(L.imageOverlay(layer.image, layer.extent));
      for (let y = 0; y < layer.locations.length; y++) {
        let location = layer.locations[y];
        const fullAddress = `${location.street}, ${location.city}, ${location.state}, ${location.postal}`;
        const googleLink = `https://www.google.com/maps/search/?api=1&query=${encodeURI(
          fullAddress
        )}`;
        if (location.lat && location.lng) {
          let icon = new L.Icon.Default();
          if (location.marker_image) {
            let url = location.marker_image.url;
            let width = location.marker_image.width;
            let height = location.marker_image.height;
            icon = L.icon({
              iconUrl: url,
              iconSize: [width, height],
              iconAnchor: [width / 2, height],
              popupAnchor: [0, -height],
              tooltipAnchor: [width / 2, -height / 2],
            });
          }
          layerGroup.push(
            L.marker([location.lat, location.lng], {
              icon: icon,
            })
              .bindTooltip(location.title)
              .bindPopup(
                `
                ${
                  location.image
                    ? `<img src="${location.image}" alt="${location.title}" class="mw-100 pb-2"/>`
                    : ""
                }
                <h4>${location.title}</h4>
                <dl>
                  ${
                    location.phone
                      ? `<dt>Phone</dt><dd><a href="tel:${location.phone}">${location.phone}</a></dd>`
                      : ""
                  }
                  ${
                    location.website
                      ? `<dt>Website</dt><dd><a href="${location.website}" target="_blank" rel="noopener noreferrer">${location.website}</a></dd>`
                      : ""
                  }
                  ${
                    location.description
                      ? `<dt>Description</dt><dd>${location.description}</dd>`
                      : ""
                  }
                  ${
                    location.display_address
                      ? `<dt>Address</dt><dd><a href="${googleLink}" target="_blank">${location.street}<br/>${location.city}, ${location.state} ${location.postal}</a></dd>`
                      : ""
                  }
                </dl>
              `
              )
              .on("popupopen", function (evt) {
                $(".leaflet-popup-content img").on("load", function () {
                  window.map._popup.update();
                });
                console.log("Opened popup for " + location.title);
                gtag("event", "location_open", {
                  event_label: location.title,
                  event_category: "location",
                });
              })
          );
        }
      }
      for (let y = 0; y < layer.polylines.length; y++) {
        let polyline = layer.polylines[y];
        layerGroup.push(
          L.polyline(polyline.coordinates, {
            color: polyline.color,
            weight: polyline.weight,
          }).bindTooltip(polyline.title)
        );
      }
      mapLayers[layer.title] = L.layerGroup(layerGroup);
      mapLayers[layer.title].on("add", function () {
        console.log("Added layer " + layer.title);
        gtag("event", "map_layer_add", {
          event_label: layer.title,
          event_category: "map_layer",
        });
      });
      mapLayers[layer.title].on("remove", function () {
        console.log("Removed layer " + layer.title);
        gtag("event", "map_layer_remove", {
          event_label: layer.title,
          event_category: "map_layer",
        });
      });
    }

    // Create map w/ options
    window.map = L.map("map", {
      layers: [tileLayer],
      tap: false,
      scrollWheelZoom: false,
    });

    // Add layer controls
    L.control
      .layers(
        {
          "Yadkin Valley, NC": tileLayer,
        },
        mapLayers,
        {
          collapsed: false,
        }
      )
      .addTo(window.map);

    // Enable all layers
    for (let i = 0; i < data.layers.length; i++) {
      const layer = data.layers[i];
      if (layer.show_on_load) {
        window.map.addLayer(mapLayers[layer.title]);
      }
    }

    // Set view to bounds of first layer (globalExtent)
    window.map.fitBounds(globalExtent);

    // Zoom in by one per client request
    window.map.zoomIn();
  });
});
