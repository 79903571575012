$(document).ready(function() {
  $("#id_date, .dateinput").pickadate({
    format: "yyyy-mm-dd",
    formatSubmit: "yyyy-mm-dd",
    hiddenName: true
  });

  $(".form-page-form").on("submit", function() {
    $(this)
      .find(".btn")
      .prop("disabled", true);
  });
});
