$(document).ready(function() {
  // show/hide filters on mobile
  $("#filtersBtn").on("click", function() {
    $("#show").toggle();
    $("#hide").toggle();
    $("#attractionFilters").slideToggle();
  });

  $("#relatedCarousel").slick({
    dots: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    prevArrow: $("#relatedPrev"),
    nextArrow: $("#relatedNext"),
    appendDots: $("#relatedDots"),
    responsive: [
      {
        breakpoint: 1199.98,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 991.98,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 767.98,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
